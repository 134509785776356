$color-primary: #f5b43d;
$color-primary-opaque: rgba(245, 192, 92, 0.932);
$color-text: #cfcfcf;

$light-gray: #bbbbbb;
$dark-gray: #1f1f1f;

$font-size-text-mobile: 16px;
$font-size-title-mobile: 20px;
$font-size-subtitle-mobile: 18px;

$font-size-text-desktop: 18px;
$font-size-title-desktop: 22px;
$font-size-subtitle-desktop: 20px;

$screen-mobile: 576px;
$screen-small: 768px;
$screen-medium: 992px;
$screen-large: 1200px;

$screen-max-width: 1200px;
