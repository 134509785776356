@import "../../scss/variables.scss";

.biography {
  display: flex;
  margin: 80px 0 150px 0;
  max-width: $screen-max-width;

  &__photo {
    position: relative;

    img {
      z-index: 2;
      width: 350px;
      position: relative;
      border-radius: 80px;
      box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.15);
    }

    &::after {
      content: "";
      top: 0;
      z-index: 1;
      width: 350px;
      height: 350px;
      opacity: 0.3;
      display: block;
      border-radius: 65px;
      border: 1px solid $color-primary;
      position: absolute;
      transform: rotate(-8deg);
      background-color: transparent;
    }
  }

  &__text {
    margin-left: 40px;

    .title {
      display: flex;
      align-items: center;
      margin: 20px 0 0 0;

      &__name {
        font-size: 42px;
        font-weight: bold;
        margin-right: 15px;
        color: $color-primary;
      }

      &__role {
        font-size: 19px;
        font-weight: bold;
        padding: 1px 15px;
        border-radius: 14px;
        color: $color-primary;
        background-color: transparent;
        border: 1px solid $color-primary-opaque;
        box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.15);
      }
    }

    .bio {
      color: $color-text;
      font-size: $font-size-text-desktop;
    }
  }
}

@media screen and (max-width: $screen-medium) {
  .biography {
    &__photo {
      img {
        width: 240px;
        border-radius: 60px;
      }

      &::after {
        content: "";
        width: 240px;
        height: 240px;
        border-radius: 50px;
      }
    }

    &__text {
      .title {
        flex-direction: column;
        align-items: flex-start;
        margin: 0 0 20px 0;

        &__name {
          font-size: 36px;
          line-height: 50px;
          margin: 0;
        }

        &__role {
          font-size: 20px;
          border-radius: 12px;
          padding: 1px 12px;
          margin-left: -5px;
        }
      }
    }
  }
}

@media screen and (max-width: $screen-small) {
  .biography {
    margin: 40px 0 60px 0;
    flex-direction: column;

    &__text {
      margin: 10px 0 0 0;

      .title {
        font-size: 36px;

        &__role {
          font-size: 16px;
          font-weight: bold;
          padding: 1px 13px;
          border-radius: 12px;
          color: $color-primary;
          background-color: transparent;
          border: 1px solid $color-primary-opaque;
          box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.15);
        }
      }

      .bio {
        font-size: $font-size-text-mobile;
      }
    }
  }
}
