@import "../../scss/variables.scss";

.footer {
  width: 100%;
  padding: 15px 0;
  max-width: $screen-max-width;
  border-left: 6px solid #252525;

  &__text {
    font-size: 18px;
    margin-left: 25px;
    color: $color-text;

    span {
      color: $color-primary;
    }
  }
}

@media screen and (max-width: $screen-mobile) {
  .footer {
    border-left: 4px solid #252525;

    &__text {
      font-size: 14px;
      margin-left: 15px;
    }
  }
}
