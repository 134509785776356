@import "../../scss/variables.scss";

.curiosity {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  max-width: $screen-max-width;
  border-left: 6px solid #1f1f1f;

  &::before {
    content: "";
    left: -12px;
    width: 18px;
    height: 18px;
    position: absolute;
    border-radius: 10px;
    top: calc(50% - 10px);
    background-color: #1f1f1f;
  }

  &__button,
  &__button--spin {
    width: 64px;
    height: 64px;
    border: none;
    color: #fff;
    display: flex;
    cursor: pointer;
    border-radius: 50px;
    align-items: center;
    margin: 0 15px 0 25px;
    justify-content: center;
    transition: all 0.15s;
    background-color: $color-primary;
    box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.15);

    &--spin {
      animation: spin 400ms;
    }

    i {
      font-size: 28px;
    }

    &:hover {
      scale: 1.1;
    }
  }

  &__text {
    display: flex;
    min-height: 140px;
    color: $color-text;
    align-items: center;
    font-size: $font-size-text-desktop;
  }
}

@media screen and (max-width: $screen-small) {
  .curiosity {
    border-left: 4px solid #1f1f1f;

    &::before {
      content: "";
      left: -9px;
      width: 14px;
      height: 14px;
    }

    &__text {
      min-height: 110px;
      font-size: $font-size-text-mobile;
    }

    &__button,
    &__button--spin {
      width: 40px;
      height: 40px;
      min-width: 40px;
      min-height: 40px;
      margin: 0 10px 0 15px;

      i {
        font-size: 18px;
      }
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
