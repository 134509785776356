@import "../../scss/variables.scss";

.testimonials {
  width: 100%;
  display: flex;
  padding: 80px 0;
  position: relative;
  align-items: center;
  max-width: $screen-max-width;
  border-left: 6px solid #1f1f1f;

  // &:hover {
  //   &::before {
  //     box-shadow: 0 0 10px 0 $color-primary;
  //     background-color: $color-primary-opaque;
  //   }
  // }

  &::before {
    top: 170px;
    left: -12px;
    content: "";
    width: 18px;
    height: 18px;
    position: absolute;
    border-radius: 10px;
    transition: all 0.3s;
    background-color: #1f1f1f;
  }

  &__text {
    .description {
      max-width: 760px;
      margin-bottom: 10px;
      color: $color-text;
      font-size: $font-size-text-desktop;
    }

    .person {
      display: flex;
      font-size: 16px;
      font-weight: bold;
      color: $color-primary;

      &__bullet {
        margin: 0 7px;
      }

      &__role {
        font-weight: normal;
      }
    }
  }

  &__photo {
    img {
      width: 200px;
      margin: 0 25px;
      border-radius: 60px;
      box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.2);
    }
  }
}

@media screen and (max-width: $screen-mobile) {
  .testimonials {
    padding: 40px 0;
    border-left: 4px solid #1f1f1f;
    flex-direction: column-reverse;
    align-items: flex-start;

    &::before {
      left: -9px;
      width: 14px;
      height: 14px;
    }

    &__text {
      margin: 0 10px 0 15px;

      .description {
        font-size: $font-size-text-mobile;
      }

      .person {
        font-size: 14px;
        margin-top: 10px;
        flex-direction: column;
        margin: 15px 0 0 70px;

        &__bullet {
          display: none;
        }
      }
    }

    &__photo {
      margin-top: -55px;

      img {
        width: 60px;
        margin: 0 15px;
        border-radius: 22px;
      }
    }
  }
}
