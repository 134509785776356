@import "../../scss/variables.scss";

.navbar-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #252525;
  padding: 30px 40px;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: $screen-max-width;
  width: 100%;

  &__logo {
    color: #eee;
    font-size: 24px;
    letter-spacing: 2px;
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;

    &:hover:after {
      width: 25%;
    }

    &:after {
      content: "";
      width: 50%;
      height: 4px;
      display: block;
      border-radius: 10px;
      background-color: $color-primary;
      transition: all ease 0.3s;
    }
  }

  &__container {
    display: flex;
  }

  &__items {
    display: flex;
    align-items: center;

    .item {
      margin: 0 18px;
      font-size: 18px;
      color: $light-gray;
      text-decoration: none;

      &:hover {
        color: $color-primary;
        transition: all ease 0.5s;

        &:after {
          width: 55%;
        }
      }

      &:after {
        content: "";
        width: 0;
        height: 4px;
        display: block;
        border-radius: 10px;
        transition: all ease 0.5s;
        background-color: $color-primary;
      }
    }
  }

  &__flags {
    display: flex;
    margin-left: 15px;

    .selected {
      z-index: 1;
      border: 2px solid #fff;
    }

    .flag {
      width: 36px;
      border-radius: 50%;
      cursor: pointer;

      &:nth-child(1) {
        transform: translateX(36px);
        transition: transform 0.5s ease;
      }

      &:nth-child(3) {
        transform: translateX(-36px);
        transition: transform 0.5s ease;
      }
    }

    &:hover > {
      .flag:nth-child(1) {
        transform: translateX(-5px);
        transition: transform 0.5s ease;
      }
      .flag:nth-child(3) {
        transform: translateX(5px);
        transition: transform 0.5s ease;
      }
    }
  }
}

@media (max-width: $screen-small) {
  .navbar-wrapper {
    padding: 10px 15px;
  }

  .navbar {
    &__items {
      display: none;
    }

    &__flags {
      width: 68px;

      .flag {
        width: 30px;

        &:nth-child(1) {
          transform: translateX(30px);
          transition: transform 0.5s ease;
        }

        &:nth-child(3) {
          transform: translateX(-30px);
          transition: transform 0.5s ease;
        }
      }

      &:hover > {
        .flag:nth-child(1) {
          transform: translateX(-5px);
          transition: transform 0.5s ease;
        }
        .flag:nth-child(3) {
          transform: translateX(-105px);
          transition: transform 0.5s ease;
        }
      }
    }
  }
}
