@import "../../scss/variables.scss";

.section {
  display: flex;
  align-items: center;
  flex-direction: column;
}

@media screen and (max-width: $screen-large) {
  .section {
    padding: 0 40px;
  }
}

@media screen and (max-width: $screen-small) {
  .section {
    padding: 0 20px;
  }
}
