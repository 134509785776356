@import "../../scss/variables.scss";

.timeline {
  width: 100%;
  max-width: $screen-max-width;
  border-left: 6px solid #252525;
  transition: all 0.3s;

  .timeline-wrapper {
    margin-top: 50px;

    .title {
      color: #575757;
      margin: 0 0 10px 25px;
      font-size: $font-size-title-desktop;
    }

    .item {
      display: flex;
      position: relative;
      text-decoration: none;
      margin: 15px 10px 40px 25px;

      &:hover {
        &::before {
          box-shadow: 0 0 10px 0 $color-primary;
          background-color: $color-primary-opaque;
        }

        .role__link {
          opacity: 1;
        }
      }

      &::before {
        top: 32px;
        left: -37px;
        content: "";
        width: 18px;
        height: 18px;
        position: absolute;
        border-radius: 10px;
        transition: all 0.3s;
        background-color: #252525;
      }

      &__photo {
        img {
          width: 96px;
          border-radius: 15px;
          box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.15);
        }
      }

      &__info {
        margin-left: 15px;

        .role {
          font-weight: bold;
          line-height: 22px;
          color: $color-primary;
          font-size: $font-size-subtitle-desktop;

          &__link {
            opacity: 0;
            padding: 7px;
            font-size: 15px;
            margin-left: 8px;
            border-radius: 6px;
            transition: all 0.2s;
            color: $color-primary;
            background-color: #2f2f2f;
          }
        }

        .company {
          font-weight: bold;
          color: $color-text;
          font-size: $font-size-subtitle-desktop;
        }

        .period {
          font-size: 16px;
          color: #919191;
          line-height: 20px;
        }

        .description {
          max-width: 840px;
          line-height: 28px;
          color: $color-text;
          margin: 5px 0 10px 0;
          font-size: $font-size-text-desktop;

          p {
            margin-bottom: 15px;
          }
        }

        .skills {
          span {
            font-size: 13px;
            padding: 2px 13px;
            margin: 0 6px 5px 0;
            transition: all 0.2s;
            border-radius: 15px;
            color: $color-primary;
            display: inline-block;
            background-color: #2e2e2e;
            box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.15);
          }
        }
      }
    }
  }
}

@media screen and (max-width: $screen-mobile) {
  .timeline {
    border-left: 4px solid #252525;

    .timeline-wrapper {
      margin-top: 35px;

      .title {
        margin-left: 15px;
        font-size: $font-size-title-mobile;
      }

      .item {
        flex-direction: column;
        margin: 5px 0 30px 15px;

        &::before {
          top: 32px;
          left: -24px;
          width: 14px;
          height: 14px;
          border-radius: 10px;
        }

        &:hover {
          &::before {
            box-shadow: none;
            background-color: #252525;
          }
        }

        &__photo {
          float: left;
          margin-bottom: 10px;
        }

        &__info {
          margin-left: 0;

          .role {
            line-height: 30px;
            font-size: $font-size-title-mobile;

            &__link {
              opacity: 1;
            }
          }

          .company {
            font-size: $font-size-title-mobile;
          }

          .description {
            font-size: $font-size-text-mobile;
          }
        }
      }
    }
  }
}
