@import "../../scss/variables.scss";

.privacy-policy {
  padding: 0 10vw;
  background-color: #fff;
  height: 100vh;
  color: $dark-gray;

  &__title {
    padding-top: 8px;
    color: #3da9be;
  }

  &__name {
    background-color: $color-primary;
    color: #fff;
    text-align: center;
    padding: 8px;
    border-radius: 8px;
  }
}
