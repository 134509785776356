@import "../../scss/variables.scss";

.back-to-top {
  z-index: 10;
  width: 15px;
  height: 15px;
  right: 15px;
  bottom: 15px;
  padding: 10px;
  display: flex;
  border: none;
  position: fixed;
  align-items: center;
  color: $color-primary;
  border-radius: 100%;
  justify-content: center;
  transition: all 0.5s ease;
  background-color: transparent;
  cursor: pointer;

  i {
    font-size: 20px;
  }

  &:hover {
    color: $dark-gray;
    background-color: $color-primary;
    transition: all 0.5s ease;
  }
}

.hidden {
  display: none;
}

@media screen and (max-width: $screen-mobile) {
  .back-to-top {
    right: 5px;
    bottom: 5px;
    width: 15px;
    height: 15px;
    background-color: #2f2f2f94;

    &:hover {
      color: $color-primary;
      background-color: transparent;
    }
  }
}
