@import "../../scss/variables.scss";

.contact {
  width: 100%;
  display: flex;
  position: relative;
  padding: 50px 0 30px 0;
  max-width: $screen-max-width;
  border-left: 6px solid #252525;

  &::before {
    top: 58px;
    left: -12px;
    content: "";
    width: 18px;
    height: 18px;
    position: absolute;
    border-radius: 10px;
    background-color: #252525;
  }

  &__social {
    margin-left: 25px;

    .text {
      color: #575757;
      font-weight: bold;
      margin-bottom: 10px;
      font-size: $font-size-title-desktop;
    }

    .socials {
      display: flex;

      a {
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        margin-right: 8px;
        border-radius: 12px;
        justify-content: center;
        text-decoration: none;
        transition: all 0.4s ease;
        background-color: #2e2e2e;

        &:hover {
          background-color: $color-primary;

          i {
            color: #2e2e2e;
          }
        }

        i {
          font-size: 24px;
          color: $color-primary;
          transition: all 0.4s ease;
        }
      }
    }
  }

  &__avatar {
    border-radius: 100%;
    box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.15);

    img {
      width: 250px;
    }
  }
}

@media screen and (max-width: $screen-mobile) {
  .contact {
    flex-direction: column;
    padding: 20px 0 30px 0;
    border-left: 4px solid #252525;

    &::before {
      top: 48px;
      left: -9px;
      width: 14px;
      height: 14px;
    }

    &__social {
      margin: 20px 0 0 0;
      padding-left: 15px;

      .text {
        margin-bottom: 5px;
        font-size: $font-size-title-mobile;
      }

      .socials {
        a {
          width: 36px;
          height: 36px;
          margin-right: 6px;

          i {
            font-size: 20px;
          }
        }
      }
    }
  }
}
